import React from "react";
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import favicon from '../images/favicon.ico';
import Feed from "instagram-feed-embed";

//excendo components
import Navbar from '../components/navbar/navbar';
import Button from "../components/button/button";
import Footer from '../components/footer/footer';
import LatestNews from "../components/news/news";
import Carousel from '../components/carousel/carousel';
import { Fade, Slide } from 'react-reveal';

//images
import img_hero from "../images/hero-image.png";

function Home() {
  return (
    <Layout>
      <Helmet>
        <title>Excendo Redovisning AB</title>
        <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
        <meta name="description" content="" />
        <meta name="keywords" content="" />
        <meta property="og:title" content="Excendo Redovisning AB" />
        <meta property="og:tyope" content="website" />
        <meta property="og:discription" content="" />
        <meta property="og:image" content="" />
        <meta property="og:locale" content="sv_SE" />
        <meta property="og:url" content="http://www.excendo.se/" />
        <link rel="cannonical" href="http://www.excendo.se/" />
      </Helmet>

      <Navbar />

      <section>
        {/*-------------- Hero Section - START ---------------*/}
        <div className="w-screen bg-hero-excendo bg-top bg-no-repeat bg-cover -mt-20 pt-20">
          <div className="container mx-auto px-4 xl:px-24">
            <div className="w-full flex flex-col lg:flex-row items-center justify-center">
                <div className="w-full lg:w-1/2 flex flex-col justify-center lg:justify-start mt-24 lg:my-40">
                  <div className="w-full md:w-10/12 mx-auto lg:mx-0">
                    <p className="text-center lg:text-left">
                      <span className="bg-white text-blue-excendo font-semibold py-1 px-4 rounded-full shadow-md">#thebyrå</span>
                    </p>
                    <h1 className="mt-10 text-center lg:text-left text-4xl sm:text-5xl 2xl:text-6xl font-extrabold text-black-excendo filter drop-shadow-sm custome_hero_title">Tillsammans bygger vi <span className="text-blue-excendo">stabila</span> företag</h1>
                    <p className="text-center lg:text-left mt-10 lg:text-xl text-gray-excendo ">Förenkla ditt företagande genom att välja Excendo som din ekonomipartner med fokus på hög kundnytta</p>
                  </div>
                    <div className="mt-12 xl:mt-16 flex justify-center lg:justify-start">
                      <Button color="orange" href="kontakt" text="Kom i Kontakt" />
                    </div>
                </div>
                <div className="w-full md:w-8/12 lg:w-1/2">
                  <div className="pl-0 xl:pl-16 mt-6 mb-20 lg:mb-0">
                    <img src={img_hero} className="object-cover object-center h-full w-full" alt="Excendo Redovisning" />
                  </div>
                </div>
            </div>
          </div>
        </div>
        {/*-------------- Hero Section - END ---------------*/}
      </section>


      <Fade>
        <section>
          {/*-------------- Våra tjänster - START ---------------*/}
          <div className="container mx-auto px-4 xl:px-24 my-32">
            <Slide top>
              <div className="w-full">
                <div className="w-full lg:w-7/12 2xl:w-5/12 mx-auto flex flex-col items-center justify-center">
                  <p className="text-center">
                    <span className="text-blue-excendo font-semibold py-1 px-4 rounded-full shadow-md">Våra Tjänster</span>
                  </p>
                  <h2 className="text-3xl lg:text-4xl font-semibold text-black-excendo text-center mt-10 filter drop-shadow-md">Förenkla ditt företagande</h2>
                  <p className="text-center text-gray-excendo mb-10 mt-4 lg:mt-8 xl:mt-10">Vi lägger stor vikt vid att hålla en förstklassig service och en genomgående hög kvalitet på levererade tjänster samtidigt som vår målsättning är att fullgöra våra uppdrag på ett kostnadseffektivt sätt</p>
                </div>
              </div>
            </Slide>
            <Fade bottom cascade>
              <div className="w-full flex flex-wrap items-center justify-between">
                <div className="w-full md:w-6/12 lg:w-4/12 h-44 pb-2 md:pr-2">
                  <Link to="/arsredovisning">
                    <div className="w-full h-full rounded-md flex items-center justify-center bg-arsredovisning bg-center bg-no-repeat bg-cover hover:opacity-90">
                      <h2 className="text-white text-3xl">Årsredovisning </h2>
                    </div>
                  </Link>
                </div>
                <div className="w-full md:w-6/12 lg:w-4/12 h-44 py-2 md:pt-0 md:pl-2 lg:px-2 md:pb-2">
                  <Link to="/bokslut">
                    <div className="w-full h-full rounded-md flex items-center justify-center bg-bokslut bg-center bg-no-repeat bg-cover hover:opacity-90">
                      <h2 className="text-white text-3xl">Bokslut </h2>
                    </div>
                  </Link>
                </div>
                <div className="w-full md:w-6/12 lg:w-4/12 h-44 py-2 lg:pt-0 md:pr-2 lg:pr-0 lg:pb-2 lg:pl-2">
                  <Link to="/bokforing">
                    <div className="w-full h-full rounded-md flex items-center justify-center bg-bokforing bg-center bg-no-repeat bg-cover hover:opacity-90">
                      <h2 className="text-white text-3xl">Bokföring </h2>
                    </div>
                  </Link>
                </div>
                <div className="w-full md:w-6/12 lg:w-4/12 h-44 py-2 md:pl-2 lg:pb-0 lg:pl-0 lg:pt-2 lg:pr-2">
                  <Link to="/deklarationer">
                    <div className="w-full h-full rounded-md flex items-center justify-center bg-deklarationer bg-center bg-no-repeat bg-cover hover:opacity-90">
                      <h2 className="text-white text-3xl">Deklarationer </h2>
                    </div>
                  </Link>
                </div>
                <div className="w-full md:w-6/12 lg:w-4/12 h-44 py-2 md:pb-0 md:pr-2 md:pt-2 lg:px-2">
                  <Link to="/loneadministration">
                    <div className="w-full h-full rounded-md flex items-center justify-center bg-Loneadministration bg-center bg-no-repeat bg-cover hover:opacity-90">
                      <h2 className="text-white text-3xl">Löneadministration </h2>
                    </div>
                  </Link>
                </div>
                <div className="w-full md:w-6/12 lg:w-4/12 h-44 pt-2 md:pl-2 md:pt-2">
                  <Link to="/fakturering">
                    <div className="w-full h-full rounded-md flex items-center justify-center bg-fakturering bg-center bg-no-repeat bg-cover hover:opacity-90">
                      <h2 className="text-white text-3xl">Fakturering </h2>
                    </div>
                  </Link>
                </div>
              </div>
            </Fade>
          </div>
          {/*-------------- Våra tjänster - END ---------------*/}
        </section>
      </Fade>


      <Fade>
        <section>
          {/*-------------- Om oss - START ---------------*/}
          <div className="bg-gray-section-background">
            <div className="container mx-auto px-4 xl:px-24 py-32">
              <div className="flex flex-col-reverse lg:flex-row items-center justify-center">
                <Slide left>
                  <div className="w-full lg:w-1/2 mt-14 lg:mt-0 flex justify-center items-center">
                    <div className="w-10/12 transform -rotate-3 ">
                      <div className="custom_3d_img transform skew-x-6 pl-4 pb-4" >
                        <div className="bg-black-excendo rounded-3xl py-2 pr-1 pl-2">
                          <Carousel />
                        </div>
                      </div>
                    </div>
                  </div>
                </Slide>
                <Slide right>
                  <div className="w-full lg:w-1/2 px-12 flex flex-col items-center lg:items-start">
                    <p className="text-start mb-3">
                      <span className="bg-white text-blue-excendo font-semibold py-1 px-4 rounded-full shadow-md">#thebyrå</span>
                    </p>
                    <h2 className="text-center lg:text-left text-3xl lg:text-4xl font-semibold text-black-excendo mt-10 filter drop-shadow-md">Ditt företag är unikt</h2>
                    <p className="text-center text-gray-excendo xl:text-lg mt-4 lg:mt-8 xl:mt-10 lg:text-left sm:w-3/4 lg:w-full">
                      Varje företag är unikt och har därmed unika behov. Många företag behöver hjälp med hela
                      ekonomifunktionen, andra behöver endast hjälp med bokslut och årsredovisning. Därför tar vi ofta vid
                      där den egna företagaren eller ekonomiavdelningen inte har tid eller kunskap. På våra kontor i
                      Södertälje och Kungens Kurva hjälper vi allt från det mindre aktiebolaget till den större koncernen.
                    </p>
                    <Slide bottom>
                      <div className="mt-14 flex justify-center lg:justify-start">
                        <Button
                          color="orange"
                          href="om-oss"
                          text="Mer Info"
                        />
                      </div>
                    </Slide>
                  </div>
                </Slide>
              </div>
            </div>
          </div>
          {/*-------------- Om oss - END ---------------*/}
        </section>
      </Fade>

      
      <section>
        {/*-------------- Contact stripe - START ---------------*/}
        <div className="w-screen bg-footer bg-bottom bg-no-repeat bg-cover">
          <div className="container mx-auto px-4 xl:px-24">
            <Fade>
              <div className="w-full py-20 lg:w-7/12 2xl:w-5/12 mx-auto flex flex-col items-center justify-center">
                <h2 className="text-3xl lg:text-4xl font-semibold text-white text-center">Kontakt</h2>
                <p className="text-center text-white mt-4 lg:mt-8 xl:mt-10">Vill du veta mer om hur vi arbetar eller vill du komma i kontak med oss? Fyll i vårt kontaktformulär eller ring oss och låt vår resa tillsammans börja!</p>
                <div className="w-full mt-14 flex flex-col md:flex-row items-center justify-evenly pr-4">
                  <span>
                    <Button
                      color="orange"
                      href="/kontakt"
                      text="Kontakta Oss"
                    />
                  </span>
                  <span className="mt-10 md:mt-0">
                    <Button
                      color="white"
                      href="/kontakt"
                      text="+46(0) 8 - 550 350 55"
                    />
                  </span>
                </div>
              </div>
            </Fade>
          </div>
        </div>
        {/*-------------- Contact stripe - END ---------------*/}
      </section>
   



      <section>
        {/*-------------- Latest news - START ---------------*/}
        <div className="bg-gray-section-background"> 
          <div className="container mx-auto px-4 xl:px-24 py-32">
            <Fade>
              <Slide bottom>
                <div className="w-full">
                  <div className="w-full lg:w-7/12 2xl:w-5/12 mx-auto flex flex-col items-center justify-center">
                    <p className="text-center">
                      <span className="bg-white text-blue-excendo font-semibold py-1 px-4 rounded-full shadow-md">Nyheter</span>
                    </p>
                    <h2 className="text-3xl lg:text-4xl font-semibold text-black-excendo text-center mt-10 filter drop-shadow-md">Det senaste från Excendo</h2>
                    <p className="text-center text-gray-excendo mb-10 mt-4 lg:mt-8 xl:mt-10">Här kan du läsa mer om vad som händer hos oss och ta del av de senaste uppdateringar om våra tjänster, pågående projekt och annat intressant.</p>
                  </div>
                </div>
                <LatestNews />
              </Slide>
            </Fade>
          </div>
        </div>
        {/*-------------- Latest news - END ---------------*/}
      </section>



      <Fade>
        <section>
          {/*-------------- Instagram story - START ---------------*/}
          <div className="w-screen bg-bottom bg-no-repeat bg-cover">
            <div className="container mx-auto px-4 xl:px-24 my-32">
              <div className="w-full flex flex-col-reverse lg:flex-row justify-center items-center">
                <Slide left>
                  <div className="mt-10 lg:mt-0 w-full lg:w-1/2 flex justify-center items-center bg-shape bg-center bg-no-repeat">
                    <div className="transform -rotate-3 md:-rotate-6">
                      <div className="custom_insta_phone bg-gray-excendo transform skew-x-6 md:skew-x-12 pl-4 pb-4" >
                        <div className="flex flex-col bg-black-excendo pl-2 pr-1 pt-8 rounded-3xl">
                          <div className="custom_insta_container">
                            <Feed
                              userName="excendoredovisning"
                              limit={6}
                              width={250}
                              maxContainerHeight={460}
                            />
                          </div>
                          <div className="w-full flex justify-center items-center">
                            <span className="w-12 h-12 m-2 rounded-full bg-white opacity-20"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Slide>
                <Slide right>
                  <div className="w-full lg:w-1/2 flex justify-center items-center 2xl:mr-1">
                    <div className="w-10/12 lg:w-1/2 mx-auto flex flex-col items-center lg:items-start">
                      <p className="text-start mb-3">
                        <span className="bg-white text-blue-excendo font-semibold py-1 px-4 rounded-full shadow-md">Sociala medier</span>
                      </p>
                      <h2 className="text-center lg:text-left text-3xl lg:text-4xl mt-4 lg:mt-6 font-semibold text-black-excendo filter drop-shadow-md">Vi finns även på sociala medier</h2>
                      <p className="text-center text-gray-excendo xl:text-lg mt-4 lg:mt-6 lg:text-left sm:w-3/4 lg:w-full">Följ oss gärna i våra kanaler på sociala medier. Då kan du vara säker på att vara först med att få del av nyheter och aktiviteter.</p>
                      <Slide bottom>
                        <div className="flex justify-center lg:justify-start">
                          <div class="flex justify-center items-center mt-6 ">
                            <Link to="https://www.facebook.com/ExcendoRedovisning" target="_blank">
                              <div className="border border-orange-excendo rounded pl-1 pb-1">
                                <button className="-mr-2 -mt-2 bg-orange-excendo px-2 py-2 text-white inline-flex items-center rounded hover:opacity-90">
                                  <svg className="w-8 h-8 fill-current" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z" /></svg>
                                </button>
                              </div>
                            </Link>
                            <Link to="https://www.instagram.com/excendoredovisning" target="_blank">
                              <div className="ml-4 border border-orange-excendo rounded pl-1 pb-1">
                                <button className="-mr-2 -mt-2 bg-orange-excendo px-2 py-2 text-white inline-flex items-center rounded hover:opacity-90">
                                  <svg className="w-8 h-8 fill-current" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 16 16"><path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" /></svg>
                                </button>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </Slide>
                    </div>
                  </div>
                </Slide>
              </div>
            </div>
          </div>
          {/*-------------- Instagram story - END ---------------*/}
        </section>
      </Fade>

      <Footer />
    </Layout>
  );
}

export default Home;
import React from 'react'
import { IoIosArrowDropleftCircle, IoIosArrowDroprightCircle } from 'react-icons/io';

//images
import image_1 from "../../images/carousel/img1.jpg";
import image_2 from "../../images/carousel/img2.jpg";

class Carousel extends React.Component{
    constructor(){
        super();

        this.state = {
            slide_2: false, 
        };

    }

    render(){
        return(
            <div className="w-full h-full relative  overflow-hidden rounded-2xl flex justify-center items-center" >
                <img 
                    className="h-full w-full relative object-cover object-center block" 
                    id={!this.state.slide_2 ? "carousel_animate_left" : "carousel_animate_right"} 
                    alt="Excendo Redovisning AB"
                    src={!this.state.slide_2 ? image_1 : image_2} 
                />
                <button 
                    className="absolute left-0 text-4xl text-black-excendo" 
                    onClick={()=>{this.setState({slide_2: false})}}
                >
                    <IoIosArrowDropleftCircle className={!this.state.slide_2 ? "opacity-30" : "hover:text-blue-900"} />
                </button>
                <button 
                    className="absolute right-0 text-4xl text-black-excendo" 
                    onClick={()=>{this.setState({slide_2: true})}}
                >
                    <IoIosArrowDroprightCircle className={!this.state.slide_2 ? "hover:text-blue-900" : "opacity-30"} />
                </button>
            </div>
        );
    }
}
export default Carousel;